<template>
  <modal
      @close="$emit('close')"
      class="custom-popup medium-size"
  >
    <template slot="header">
      <span v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['importO_downloadOrders'])"></span>
      {{ $t('importO_downloadOrders.localization_value.value') }}
      </span>
    </template>
    <template slot="body">
      <div class="custom-popup__content"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate([
              'importO_downloadOrdersPlease',
              'importO_downloadPlatform',
              'importO_downloadShops',
              'importO_downloadShopsCapt',
              'importO_downloadAllTime',
              'importO_downloadPeriod',
              ])"></span>

        <div class="order-create__head-txt">{{ $t('importO_downloadOrdersPlease.localization_value.value') }}</div>
        <div class="custom-row">
          <div class="custom-col custom-col--50 custom-col--md-100">
            <DefaultSelect
              :label="$t('importO_downloadPlatform.localization_value.value')"
              :options="optionsShopTypes"
              :selectIcons="true"
              :optionsLabel="'name'"
              :optionIcoName="'name'"
              :heightIco="14"
              :selected="selectShopType"
              @change="changeShopType"
              v-bind:class="{'ui-no-valid': validation.selectShopType}"
              :error="validation.selectShopType"
              :errorTxt="$t(`${validationTranslate.selectShopType}.localization_value.value`)"
            />
          </div>
<!--          <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;md-100">-->
<!--            <DefaultInput-->
<!--              :label="$t('importO_downloadPlatform.localization_value.value')"-->
<!--              :type="'text'"-->
<!--              v-model="shopTypeName"-->
<!--              :disabled="true"-->
<!--            />-->
<!--          </div>-->

          <div class="custom-col custom-col--50 custom-col--sm-100"
               v-if="$store.getters.getCurrentUserRights.additionalPermissions &&
               $store.getters.getCurrentUserRights.additionalPermissions[PERMISSIONS.VIEW_ADMIN_ROUTE_PREFIX]"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <SearchSelect
                    :options="optionsUser"
                    :label="$t('shop_user.localization_value.value')"
                    :functionSearch="functionSearch"
                    @change="changeUsersFilter"
                    :typeSelect="'users'"
                    v-bind:class="{'ui-no-valid': validation.selectUser}"
                    :error="validation.selectUser"
                    :selected="userActive"
                    :errorTxt="$t(`${validationTranslate.selectUser}.localization_value.value`)"
                    :userEmail="true"
            />
          </div>

          <div class="custom-col custom-col--50 custom-col--md-100">
            <DefaultSelect
                :options="optionsShops"
                :label="$t('importO_downloadShops.localization_value.value')"
                :caption="$t('importO_downloadShopsCapt.localization_value.value')"
                :optionsLabel="'shop_name'"
                v-bind:class="{'ui-no-valid': validation.selectShop}"
                :error="validation.selectShop"
                :errorTxt="$t(`${validationTranslate.selectShop}.localization_value.value`)"
                @change="changeShop"
                :defaultNoOptions="'shops_noShopsSelect'"
            />
          </div>


          <div class="custom-col custom-col--50 custom-col--sm-100">
            <RadioDefault
              :label="$t('importO_downloadAllTime.localization_value.value')"
              :name="'nameRadio'"
              class="mb-2"
              :value="radioVal === radioOptions.all"
              @change.native="changeRadio(radioOptions.all)"
            />
            <RadioDefault
              :label="$t('importO_downloadPeriod.localization_value.value')"
              :name="'nameRadio'"
              class="mb-2"
              @change.native="changeRadio(radioOptions.period)"
            />
            <div class="custom-row pl-4">
              <div class="custom-col custom-col--50">
                <DatePickerDefault
                    :value="timeStart"
                    class="date-picker-time-custom spacer"
                    v-bind:class="{'ui-no-valid': validation.timeStart}"
                    :error="validation.timeStart"
                    :errorTxt="$t(`${validationTranslate.timeStart}.localization_value.value`)"
                >
                  <template slot="body">
                    <date-picker
                        v-model="timeStart"
                        valueType="format"
                        :format="'MM/DD/YYYY'"
                        :placeholder="'mm/dd/yy'"
                        :disabled="radioVal === radioOptions.all ? true : false"
                        :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    ></date-picker>
                  </template>
                </DatePickerDefault>
              </div>
              <div class="custom-col custom-col--50">
                <DatePickerDefault
                    :value="timeEnd"
                    class="date-picker-time-custom"
                    v-bind:class="{'ui-no-valid': validation.timeEnd}"
                    :error="validation.timeEnd"
                    :errorTxt="$t(`${validationTranslate.timeEnd}.localization_value.value`)"
                >
                  <template slot="body">
                    <date-picker
                        v-model="timeEnd"
                        valueType="format"
                        :format="'MM/DD/YYYY'"
                        :placeholder="'mm/dd/yy'"
                        :disabled="radioVal === radioOptions.all ? true : false"
                        :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    ></date-picker>
                  </template>
                </DatePickerDefault>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="buy-label__btn d-flex align-items-center"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['common_cancel', 'importO_downloadGenerate'])"></span>
        <span
            class="site-link site-link--alt buy-label__btn-i mr-3"
            @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>

        <MainButton
            :value="$t('importO_downloadGenerate.localization_value.value')"
            class="buy-label__btn-i"
            @click.native="generate(false)"
            v-bind:class="{'disabled-btn' : $store.getters.getImportOrdersBtn}"
        />

      </div>
      <div v-if="loadingOrders">Loading...</div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import DefaultSelect from "../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import RadioDefault from "../../../../UI/radiobuttons/RadioDefault/RadioDefault";
  import DatePicker from 'vue2-datepicker'
  import DatePickerDefault from "../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import SearchSelect from "../../../../UI/selectiones/SearchSelect/SearchSelect";
  import {usersFunc} from "../../../../../mixins/usersMixins/usersFunc";
  import {validation} from "../../../../../services/validation";
  import {radioOptions} from "./staticRadioOptions";
  // import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";

  export default {
    name: "DownloadOldOrdersPopup",
    components: {
      // DefaultInput,
      Modal,
      MainButton,
      DefaultSelect,
      RadioDefault,
      DatePicker,
      DatePickerDefault,
      SearchSelect,
    },

    mixins: [usersFunc],

    props: {
      typeShops: {
        type: Array
      }
    },

    data(){
      return {
        optionsShopTypes: [],

        optionsUser: [],

        optionsShops: [],

        shopTypeName: '',

        selectShopType: null,
        selectUser: null,
        selectShop: null,

        userActive: '',

        timeStart: '',
        timeEnd: '',

        radioVal: radioOptions.all,

        radioOptions: radioOptions,

        loadingOrders: false,


        validation: {
          selectUser: false,
          shopType: false,
          selectShop: false,
          timeStart: false,
          timeEnd: false,
        },

        validationTranslate: {
          selectUser: 'default',
          shopType: 'default',
          selectShop: 'default',
          timeStart: 'default',
          timeEnd: 'default',
        },
      }
    },

    mounted() {

      this.getShopTypes()

    },

    methods: {

      getShopTypes(){
        let etsy = this.$store.getters.getHeaderDefaultShops.etsy
        let shopify = this.$store.getters.getHeaderDefaultShops.shopify
        // let amazon = this.$store.getters.getHeaderDefaultShops.amazonmws

        this.typeShops.map(item => {
          if(item.shop.name === etsy.type) {
            etsy.id = item.shop.id
            this.optionsShopTypes.push(etsy)
            this.selectShopType = etsy
            this.shopTypeName = item.shop.currentTranslate.label
            this.changeShopType(this.selectShopType)
          }
          if(item.shop.name === shopify.type) {
            shopify.id = item.shop.id
            this.optionsShopTypes.push(shopify)
            this.shopTypeName = item.shop.currentTranslate.label
          }
          // if(item.shop.name === amazon.type) {
          //   amazon.id = item.shop.id
          //   this.optionsShopTypes.push(amazon)
          // }
        })

        // add changeShopType because delete select with shop types
        // this.changeShopType(this.selectShopType)
      },

      changeShopType(value) {
        this.selectShopType = value
        this.userActive = ''
        this.selectUser = null

        if(this.$store.getters.getCurrentUserRights.additionalPermissions &&
          !this.$store.getters.getCurrentUserRights.additionalPermissions[this.PERMISSIONS.VIEW_ADMIN_ROUTE_PREFIX]){
          this.$store.dispatch(`fetchShops`,
            `?filter[type]=${this.selectShopType.id}&page=1&limit=10000`
          ).then(() => {
            this.filterShopType()
          }).catch(error => this.createErrorLog(error))
        }
      },

      functionSearch(value, loading) {
        loading(true)
        this.getFilteredUsers(value, this, 'optionsUser', loading)
      },

      changeUsersFilter(value) {
        this.selectShop = null

        if(value === ''){
          this.userActive = ''
          this.selectUser = null
        } else {
          this.userActive = value.contacts[0].user_full_name
          this.selectUser = value
        }

        this.$store.dispatch(`fetchShops`,
          `?filter[user]=${value.id}&filter[type]=${this.selectShopType.id}&filter[shopStatus]=valid`
        ).then(() => {
          this.filterShopType()
        }).catch(error => this.createErrorLog(error))
      },

      filterShopType() {
        let shops = this.$store.getters.getShops
        shops.map((item, index) => {
          if(item.shop_oauth_param.key_and_token_exist == 0){
            shops.splice(index, 1)
          }
        })
        this.optionsShops = shops
      },

      changeShop(value) {
        this.selectShop = value
      },

      changeRadio(val){
        this.radioVal = val
      },

      generate(page = false) {
        let validationItems = {
          selectShopType: this.selectShopType,
          selectUser: this.selectUser,
          selectShop: this.selectShop,
        }

        let validationOptions = {
          selectShopType: {type: ['empty',]},
          selectShop: {type: ['empty',]},
        }

        if(this.$store.getters.getCurrentUserRights.additionalPermissions &&
          this.$store.getters.getCurrentUserRights.additionalPermissions[this.PERMISSIONS.VIEW_ADMIN_ROUTE_PREFIX]) {

          validationItems.selectUser = this.selectUser
          validationOptions.selectUser = {type: ['empty',]}
        }

        if(this.radioVal === this.radioOptions.period){
          validationItems.timeStart = this.timeStart
          validationItems.timeEnd = this.timeEnd

          validationOptions.timeStart = {type: ['empty',]}
          validationOptions.timeEnd = {type: ['empty',]}
        }


        let validate = validation(validationItems, validationOptions);
        this.validation = validate.validation;
        this.validationTranslate = validate.validationError

        if(validate.isValidate) {
          let data = {}

          if(this.radioVal === this.radioOptions.period){
            data.range_type = 'range'
            data.date_from = this.$moment(this.timeStart).format('Y-MM-DD')
            data.date_to = this.$moment(this.timeEnd).format('Y-MM-DD')
          }

          if(page){
            data.page = page
          }

          this.loadingOrders = true

          this.$store.dispatch('downloadOldImportOrders', {id: this.selectShop.id, data: data}).then((response) => {

            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              if (response.data.data.next_page !== null) {
                this.generate(response.data.data.next_page)
              } else {
                this.loadingOrders = false

                this.$emit('reloadTable')
                this.$emit('close')

                setTimeout(() => {
                  this.openNotify('success', 'common_notificationStatusChanged')
                }, 200)
              }
            } else {
              this.loadingOrders = false
              setTimeout(() => {
                this.openNotify('success', 'common_notificationUndefinedError')
              }, 200)
            }
          })
        }
      }


    }
  }

</script>

<style scoped>
  .order-create__head-txt{
    font-size: 16px;
  }
</style>
