<template>
  <modal
      @close="$emit('close')"
      class="transaction-info-modal transaction-info"
  >
    <template slot="header">
      {{$store.getters.getImportOrdersItem.id}} —
      {{ $store.getters.getImportOrdersItem.original_created_at | moment("DD MMM, YYYY") }} at
      {{ $store.getters.getImportOrdersItem.original_created_at | moment("HH:mm") }}
    </template>
    <template slot="body">
      <div class="transaction-info__content" v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(translations)"></span>
        <div class="transaction-info__section">
          <div class="transaction-info__section-title">
            {{ $t('importO_orderDetails.localization_value.value') }}
          </div>

          <div class="transaction-info__row">
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{ $t('importO_payPalTransaction.localization_value.value') }}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="$store.getters.getImportOrdersItem.transaction_number"
                />
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{ $t('importO_customer.localization_value.value') }}
              </div>
              <div class="transaction-info__txt">
                {{$store.getters.getImportOrdersItem.original_first_name}} {{$store.getters.getImportOrdersItem.original_last_name}}
              </div>
            </div>
            <div class="transaction-info__col" v-if="$store.getters.getCurrentUserRights.additionalPermissions[PERMISSIONS.VIEW_ADMIN_ROUTE_PREFIX]">
              <div class="transaction-info__label">
                {{ $t('importO_user.localization_value.value') }}
              </div>
              <div class="transaction-info__txt">
                {{$store.getters.getImportOrdersItem.user.user_personal_contact.user_full_name}}
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{ $t('importO_eMail.localization_value.value') }}
              </div>
              <div class="transaction-info__txt" style="word-break: break-all;">
                <ValueHelper
                        :value="$store.getters.getImportOrdersItem.original_email"
                />
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{ $t('importO_address.localization_value.value') }}
              </div>
              <div class="transaction-info__txt">
                {{$store.getters.getImportOrdersItem.original_country}}<br>
                {{$store.getters.getImportOrdersItem.original_post_code}}<br>
                {{$store.getters.getImportOrdersItem.original_state}}<br>
                {{$store.getters.getImportOrdersItem.original_city}}<br>
                {{$store.getters.getImportOrdersItem.original_address_line_1}}
                {{$store.getters.getImportOrdersItem.original_address_line_2}}
                <!--{{$store.getters.getImportOrdersItem.formated_address}}-->
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{ $t('importO_itemNumber.localization_value.value') }}
              </div>
              <div class="transaction-info__txt" style="word-break: break-all;">
                <ValueHelper
                        :value="$store.getters.getImportOrdersItem.item_number"
                />
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{ $t('importO_orderId.localization_value.value') }}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="$store.getters.getImportOrdersItem.original_id"
                />
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{ $t('importO_comment.localization_value.value') }}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="$store.getters.getImportOrdersItem.comment"
                />
              </div>
            </div>

            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{ $t('importO_shipped.localization_value.value') }}
              </div>
              <div class="transaction-info__txt">
                <template v-if="$store.getters.getImportOrdersItem.shipped_at !== null">
                  {{$store.getters.getImportOrdersItem.shipped_at | moment("DD MMM, YYYY")}}. at
                  {{$store.getters.getImportOrdersItem.shipped_at | moment("HH:MM")}}
                </template>
                <template v-else>
                  —
                </template>
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{ $t('importO_trackingNumber.localization_value.value') }}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="$store.getters.getImportOrdersItem.tracking_number"
                />
              </div>
            </div>
            <!--<div class="transaction-info__col">-->
              <!--<div class="transaction-info__label">-->
                <!--{{ $t('importO_orderStatus.localization_value.value') }}-->
              <!--</div>-->
              <!--<div class="transaction-info__txt">-->
                <!--<ValueHelper-->
                        <!--:value="$store.getters.getImportOrdersItem.original_status"-->
                <!--/>-->
              <!--</div>-->
            <!--</div>-->


            <!--<div class="transaction-info__col">-->
              <!--<div class="transaction-info__label">-->
                <!--{{ $t('importO_uSAShipping.localization_value.value') }}-->
              <!--</div>-->
              <!--<div class="transaction-info__txt">-->
                <!--{{$store.getters.getImportOrdersItem.shipping_company !== null ? $store.getters.getImportOrdersItem.shipping_company : '-'}}-->
              <!--</div>-->
            <!--</div>-->
            <div class="transaction-info__col" v-if="$store.getters.getImportOrdersItem.shop_type_id !== 2">
              <div class="transaction-info__label">
                {{ $t('importO_orderPrice.localization_value.value') }}
              </div>
              <div class="transaction-info__txt">
                {{$store.getters.getImportOrdersItem.priceData.sub_total}}
                {{$store.getters.getImportOrdersItem.original_currency}}
              </div>
            </div>
            <div class="transaction-info__col" v-if="$store.getters.getImportOrdersItem.shop_type_id !== 2">
              <div class="transaction-info__label">
                {{ $t('importO_deliveryCost.localization_value.value') }}
              </div>
              <div class="transaction-info__txt">
                {{$store.getters.getImportOrdersItem.priceData.delivery_cost}}
                {{$store.getters.getImportOrdersItem.original_currency}}
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{ $t('importO_total.localization_value.value') }}
              </div>
              <div class="transaction-info__txt">
                {{$store.getters.getImportOrdersItem.original_total}}
                {{$store.getters.getImportOrdersItem.original_currency}}
              </div>
            </div>
            <div class="transaction-info__col" v-if="$store.getters.getImportOrdersItem.priceData.tax !== 0 && $store.getters.getImportOrdersItem.shop_type_id !== 2">
              <div class="transaction-info__label">
                {{ $t('cuspayments_tax.localization_value.value') }}
              </div>
              <div class="transaction-info__txt">
                {{$store.getters.getImportOrdersItem.priceData.tax}}
                {{$store.getters.getImportOrdersItem.original_currency}}
              </div>
            </div>
          </div>

          <div v-if="$store.getters.getImportOrdersItem.products.length > 0">
            <div class="transaction-info__section-title">
              {{ $t('importO_products.localization_value.value') }}
            </div>

            <div class="transaction-info__row"
                 v-for="(item, index) in $store.getters.getImportOrdersItem.products"
                 :key="index">
              <div class="transaction-info__col">
                <div class="transaction-info__label">
                  {{ $t('importO_productName.localization_value.value') }}
                </div>
                <div class="transaction-info__txt">
                  {{item.original_name}}
                </div>
              </div>
              <div class="transaction-info__col">
                <div class="transaction-info__label">
                  {{ $t('importO_productCount.localization_value.value') }}
                </div>
                <div class="transaction-info__txt">
                  {{item.original_quantity}}
                </div>
              </div>
            </div>
          </div>


        </div>

      </div>
    </template>
    <template slot="footer">
      <!--<div class="transaction-info__btn__btn">-->
        <!--<router-link :to="'/#'">-->
          <!--<MainButton-->
              <!--:value="$t('cuspayments_requestRefund.localization_value.value')"-->
              <!--class="transaction-info__btn-i wfc"-->
          <!--/>-->
        <!--</router-link>-->
      <!--</div>-->
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import {CUSTOMERS_PAYMENTS_STATUSES} from "../../../../../staticData/staticVariables";
  import ValueHelper from "../../../../coreComponents/ValueHelper/ValueHelper";



  export default {
    name: "OrderInfo",
    components: {
      Modal,
      ValueHelper,
    },

    props: ['typeShops'],

    watch: {
      typeShops(newVal) {
        this.typeShops = newVal
      },
    },

    data() {
      return {
        CUSTOMERS_PAYMENTS_STATUSES: CUSTOMERS_PAYMENTS_STATUSES,
        translations: [
          'importO_orderDetails',
          'importO_payPalTransaction',
          'importO_customer',
          'importO_eMail',
          'importO_address',
          'importO_itemNumber',
          'importO_comment',
          'importO_shipped',
          'importO_orderPrice',
          'importO_deliveryCost',
          'importO_user',
          'importO_trackingNumber',
          'importO_products',
          'importO_productName',
          'importO_productCount',
          'importO_orderId',
          'importO_total',
          'cuspayments_tax',
        ]
      }
    },
  }

</script>

<style lang="scss">

</style>
