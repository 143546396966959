<template>
	<div class="fragment">
		<ImportOrdersHead
						@toggleFilter="toggleFilter"
						@filter="$emit('filter')"
						@changeFilter="changeFilter"
						:showFilter="showFilter"
						:typeShops="typeShops"
						@changeShopType="changeShopType"
						:countFilterParams="countFilterParams"
						@selectAll="selectAll"
						@reloadTable="$emit('reloadTable')"
		/>
		<div class="table-filter-wrap">

			<ImportOrdersFilter
					:showFilter="showFilter"
					@close="closeFilter"
          @changeFilter="changeFilter"
					@resetFilter="$emit('resetFilter')"
          :filterGetParams="filterGetParams"
			/>

			<ImportOrdersTable
							:countFilterParams="countFilterParams"
							:selectedNow="selectedNow"
							:typeShops="typeShops"
							@showMore="$emit('showMore')"
							@resetFilter="$emit('resetFilter')"
              @downloadFiles="type => $emit('downloadFiles', type)"
              @changePerPage="(perPage) => {$emit('changePerPage', perPage)}"
			/>
		</div>

	</div>
</template>

<script>
	import ImportOrdersTable from "./ImportOrdersTable/ImportOrdersTable";
	import ImportOrdersFilter from "./ImportOrdersFilter/ImportOrdersFilter";
	import ImportOrdersHead from "./ImportOrdersHead/ImportOrdersHead";

	export default {
		name: "ImportOrdersTableAllUser",

		components: {
			ImportOrdersHead,
			ImportOrdersFilter,
			ImportOrdersTable,
		},

		data() {
			return {
				showFilter: false,
				selectedNow: false,
			}
		},

		props: [
			'typeShops',
			'filterGetParams',
			'countFilterParams',
		],

		watch: {
			typeShops(newVal) {
				this.typeShops = newVal
			},
      filterGetParams(newVal) {
				this.filterGetParams = newVal
			},
			countFilterParams(newVal) {
				this.countFilterParams = newVal
			},
		},

		methods: {
			closeFilter() {
				this.showFilter = false
			},

			toggleFilter() {
				this.showFilter = !this.showFilter
			},

			changeShopType(index) {
				this.$emit('changeShopType', index)
			},

			changeFilter(data) {
				this.$emit('changeFilter', data)
			},

			selectAll(val) {
				this.selectedNow = val
			},
		}
	}
</script>

<style scoped>

</style>
