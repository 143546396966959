<template>
	<div class="fragment">
		<DownloadPluginsHead
						:filter="filter"
						@changeFilter="changeFilter"
		/>
		<div class="table-filter-wrap">
			<DownloadPluginsUserTable
							:filter="filter"
			/>
		</div>
	</div>
</template>

<script>
	import DownloadPluginsUserTable from "./DownloadPluginsUserTable/DownloadPluginsUserTable";
	import DownloadPluginsHead from "./DownloadPluginsHead/DownloadPluginsHead";

	export default {
		name: "DownloadPluginsUser",

		components: {
			DownloadPluginsHead,
			DownloadPluginsUserTable,
		},

		data() {
			return {
				showFilter: false,
			}
		},

		props: [
			'filter',
		],

		watch: {
			typeShops(newVal) {
				this.typeShops = newVal
			},
      filterGetParams(newVal) {
				this.filterGetParams = newVal
			},
		},

		methods: {
			changeFilter(data) {
				this.$emit('changeFilter', data)
			},
		}
	}
</script>

<style scoped>

</style>
