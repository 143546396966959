<template>
  <div class="fragment">

    <div class="content-top-line content-top-line--with-bottom-line">
      <div class="content-tabs content-tabs--separator"
      v-bind:class="{'loading-tabs': $store.getters.getImportOrdersLoading === true,}">
        <template v-for="(item, index) in dataTypeShops">
          <div class="content-tabs__item "
               :key="index"
               v-if="(item.shop && defaultShopsTypes[item.shop.name] && item.permissionVisible) || item.shop.name === 'allShop'"
               v-bind:class="{active: item.active}"
               @click="$emit('changeShopType', index)">


              <template v-if="defaultShopsTypes[item.shop.name] && defaultShopsTypes[item.shop.name].ico">
                <div class="content-tabs__ico">
                  <img :src="defaultShopsTypes[item.shop.name].ico" alt="ico">
                </div>
              </template>
              <template v-else>
                <span v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" style="top:3px; left: -18px" @click="editTranslate(['importO_allOrders'])"></span>
                  {{item.shop.currentTranslate.label}}
                </span>

              </template>
          </div>
          <div class="content-tabs__item "
               v-if="item.shop && item.shop.name === 'expressPoshtaShop'"
               :key="index"
               v-bind:class="{active: item.active}"
               @click="$emit('changeShopType', item.shop.name)"
          >
            <span v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <span class="admin-edit" style="top:3px; left: -18px" @click="editTranslate(['importO_expressPoshtaShopOrders'])"></span>
              {{item.shop.currentTranslate.label}}
            </span>
          </div>
        </template>
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter"/>
        <!--<ActivateDateButton @changeDate="changeDate" :value="$route.query.date !== undefined ? $route.query.date.split(',') : ''" class="ml-3" />-->
      </div>
    </div>

    <div class="light-bg-block light-bg-block--mobile-wrap">
      <div class="btn-left-block">
        <DefaultCheckbox
                :label="$t('warehouseManagement_selectAll.localization_value.value')"
                @input="selectAll"
        />
      </div>
      <div class="btn-right-block btn-right-block--mobile-wrap">
        <div v-if="!isAdmin && checkAmazon()" class="content-top-line-wrap ml-2" style="position: relative;"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
          'common_import',
        ])"></span>
          <TableHeadButton
              :value="$t('common_import.localization_value.value')"
              :ico="'import'"
              @click.native="changeImportPopup(true)"
          />
          <div class="likeTooltipBtn">
            <TableHeadButton
                :value="''"
                :ico="'question'"
                class="likeTooltipBtn__item"
                @click.native="downloadExample"
            />
          </div>
        </div>

        <TableHeadButton
            v-if="!isAdmin"
            :value="$t('common_importSingleOrder.localization_value.value')"
            :ico="'import'"
            @click.native="isImportSingleOrderPopup = true"
        />
        <span
                v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['importO_oldOrdersEtsy'])"></span>
          <MainButton class="btn-fit-content"
                      :value="$t('importO_oldOrdersEtsy.localization_value.value')"
                      :ico="'ico'"
                      @click.native="showDownloadOldOrdersPopup"
          >
            <template slot="ico">
              <OldOrdersFromIcon class="mt-1"/>
            </template>
          </MainButton>
        </span>
      </div>
    </div>

    <DownloadOldOrdersPopup
            @close="closeDownloadOldOrdersPopup"
            v-if="isModalDownloadOldOrdersPopup"
            :typeShops="typeShops"
            @reloadTable="$emit('reloadTable')"
    />

    <ImportPopup
        v-if="isModalImportPopup"
        @close="changeImportPopup(false)"
        @reloadTable="$emit('reloadTable')"
    />

    <ImportSingleOrderPopup
        v-if="isImportSingleOrderPopup"
        @close="isImportSingleOrderPopup = false"
        @reloadTable="$emit('reloadTable')"
    />
  </div>
</template>

<script>
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import OldOrdersFromIcon from "../../../../../../../../public/img/UI-group/old-orders-from-icon.svg?inline";
  import DownloadOldOrdersPopup from "../../../../popups/DownloadOldOrdersPopup/DownloadOldOrdersPopup";
  // import ActivateDateButton from "../../../../../../UI/buttons/ActivateDateButton/ActivateDateButton";
  import TableHeadButton from "../../../../../../coreComponents/TableComponents/TableHeadButton/TableHeadButton";
  import ImportPopup from "../../../../popups/ImportPopup/ImportPopup";
  import ImportSingleOrderPopup
    from "@/components/modules/ImportOrdersModule/popups/ImportSingleOrderPopup/ImportSingleOrderPopup";

  export default {
    name: "ImportOrdersHead",
    components: {
      ImportSingleOrderPopup,
      ImportPopup,
      MainButton,
      ToggleFilterButton,
      DefaultCheckbox,
      OldOrdersFromIcon,
      DownloadOldOrdersPopup,
      TableHeadButton,
      // ActivateDateButton,
    },

    props: [
      'showFilter',
      'typeShops',
      'countFilterParams',
    ],

    watch: {
      typeShops(newVal) {
        this.dataTypeShops = newVal
      },
    },

    data() {
      return {
        isModalChooseExpressOrderPopup: false,
        isModalDownloadOldOrdersPopup: false,
        dataTypeShops: this.typeShops,
        defaultShopsTypes: this.$store.getters.getHeaderDefaultShops,
        isModalImportPopup: false,
        isImportSingleOrderPopup: false,

        isShowSearchEngineBlock: false,
        searchField1: '',
        searchField2: '',
        searchField3: '',
        searchField4: '',
      }
    },

    methods: {
      showDownloadOldOrdersPopup() {
        this.isModalDownloadOldOrdersPopup = true
      },

      closeDownloadOldOrdersPopup() {
        this.isModalDownloadOldOrdersPopup = false
      },

      selectAll(val) {
        this.$emit('selectAll', val)
      },

      changeImportPopup(val){
        this.isModalImportPopup = val
      },

      downloadExample() {
        this.$store.dispatch('getImportExampleXLSXImportOrdersAmazon').then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            const blob = new Blob([response.data], {type: 'application/xlsx'})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = 'Order_Amazon_import_example.xlsx'
            link.click()
            URL.revokeObjectURL(link.href)
          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        })

      },

      checkAmazon() {
        return this._.find(this.typeShops, {active: true})?.shop.name === 'amazonmws'
      },

      showSearchEngineBlock(){
        this.isShowSearchEngineBlock = !this.isShowSearchEngineBlock;
      },


      // changeDate(date) {
      //   // let storeData = this.$store.getters.getShopsFilterData
      //   let data = {}
      //
      //   if(date[0] === null && date[1] === null){
      //     data.date = ''
      //   } else {
      //     data.date = date[0] + ',' + date[1]
      //   }
      //
      //   this.$emit('changeFilter', data)
      // },

    }
  }
</script>

<style scoped>
  .likeTooltipBtn{
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-20px, -8px);
  }

  .likeTooltipBtn__item{
    padding: 0;
    width: 20px;
    margin-top: -18px;
    margin-left: 4px;
  }

  @media (max-width: 1220px){
    .print-btn__btn {
      padding-right: 16px;
      padding-left: 0;
    }
  }

  @media (max-width: 991px) {
    .likeTooltipBtn {
      position: absolute;
      top: -8px;
      right: 0;
      transform: none;
    }
  }

  @media (max-width: 1600px) {
    .content-tabs__ico img {
      width: auto;
      /*height: 15px;*/
      height: 21px;
    }
  }
</style>
