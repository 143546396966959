<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['importO_order'])"></span>
        <DefaultInput
                :label="$t('importO_order.localization_value.value')"
                :type="'text'"
                v-model="order"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['common_date'])"></span>
        <DatePickerDefault
                :label="$t('common_date.localization_value.value')"
                v-model="date">
          <template slot="body">
            <date-picker
                    v-model="date"
                    ref="datePicker"
                    valueType="format"
                    range
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    :format="'MM/DD/YY'"
                    :placeholder="'mm/dd/yy ~ mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['importO_shipped'])"></span>
        <DatePickerDefault
                :label="$t('importO_shipped.localization_value.value')"
                v-model="shippedDate">
          <template slot="body">
            <date-picker
                    v-model="shippedDate"
                    ref="datePicker"
                    valueType="format"
                    range
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    :format="'MM/DD/YY'"
                    :placeholder="'mm/dd/yy ~ mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>
      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['importO_user'])"></span>
        <SearchSelect
                :options="options"
                :label="$t('importO_user.localization_value.value')"
                :optionsLabel="'email.contacts[0]'"
                :functionSearch="functionSearch"
                @change="changeUsersFilter"
                :selected="userName"
                :typeSelect="'users'"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['importO_transactionNumber'])"></span>
        <DefaultInput
                :label="$t('importO_transactionNumber.localization_value.value')"
                :type="'text'"
                v-model="transactionNumber"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['importO_customer'])"></span>
        <DefaultInput
                :label="$t('importO_customer.localization_value.value')"
                :type="'text'"
                v-model="customer"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['importO_trackingNumber'])"></span>
        <DefaultInput
                :label="$t('importO_trackingNumber.localization_value.value')"
                :type="'text'"
                v-model="trackingNumber"
        />
      </div>


      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['importO_eMail'])"></span>
        <DefaultInput
                :label="$t('importO_eMail.localization_value.value')"
                :type="'text'"
                v-model="email"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['importO_shopName'])"></span>
        <DefaultInput
                :label="$t('importO_shopName.localization_value.value')"
                :type="'text'"
                v-model="shopName"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['importO_itemNumber'])"></span>
        <DefaultInput
                :label="$t('importO_itemNumber.localization_value.value')"
                :type="'text'"
                v-model="itemNumber"
        />
      </div>






    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')"
                  class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker';
  import SearchSelect from "../../../../../../UI/selectiones/SearchSelect/SearchSelect";
  import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";

  export default {
    name: "ImportOrdersFilter",
    components: {
      FilterBlock,
      MainButton,
      DefaultInput,
      DatePickerDefault,
      DatePicker,
      SearchSelect,
    },

    props: [
      'showFilter',
      'filterGetParams',
    ],

    mixins: [filterFunc, usersFunc],

    data() {
      return {
        date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY') : '',
        shippedDate: this.filterGetParams.shippedDate ? this.generateFilterDate(this.filterGetParams.shippedDate, 'MM/DD/YY') : '',
        transactionNumber: this.filterGetParams.transactionNumber ? this.filterGetParams.transactionNumber : '',
        trackingNumber: this.filterGetParams.trackingNumber ? this.filterGetParams.trackingNumber : '',
        order: this.filterGetParams.order ? this.filterGetParams.order : '',
        customer: this.filterGetParams.customer ? this.filterGetParams.customer : '',
        email: this.filterGetParams.email ? this.filterGetParams.email : '',
        itemNumber: this.filterGetParams.itemNumber ? this.filterGetParams.itemNumber : '',
        shopName: this.filterGetParams.shopName ? this.filterGetParams.shopName : '',
        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',

        options: [],

        localFilterParams: [
          'userName',
          'userId',
          'transactionNumber',
          'trackingNumber',
          'date',
          'itemNumber',
          'order',
          'customer',
          'email',
          'shippedDate',
          'shopName',
        ]
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''
        this.shippedDate = newVal.shippedDate ? this.generateFilterDate(newVal.shippedDate, 'MM/DD/YY') : ''
        this.transactionNumber = newVal.transactionNumber ? newVal.transactionNumber : ''
        this.trackingNumber = newVal.trackingNumber ? newVal.trackingNumber : ''
        this.order = newVal.order ? newVal.order : ''
        this.customer = newVal.customer ? newVal.customer : ''
        this.email = newVal.email ? newVal.email : ''
        this.itemNumber = newVal.itemNumber ? newVal.itemNumber : ''
        this.shopName = newVal.shopName ? newVal.shopName : ''
        this.userName = newVal.userName ? newVal.userName : ''
        this.userId = newVal.userId ? newVal.userId : ''
      },
    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      functionSearch(value, loading) {
        loading(true)
        this.getFilteredUsers(value, this, 'options', loading, true)
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.localFilterParams)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.localFilterParams)
        data.date = this.changeDateParams(this, 'date')
        data.shippedDate = this.changeDateParams(this, 'shippedDate')

        this.$emit('changeFilter', data)
      },

      changeUsersFilter(value) {
        if(value) {
          this.userId = value.id
          this.userName = value.contacts[0].user_full_name
        } else {
          this.userId = ''
          this.userName = ''
        }
      },
    },
  }
</script>

<style scoped>

</style>
