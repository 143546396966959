<template>
  <div class="fragment">
    <div class="content-top-line content-top-line--with-bottom-line">
      <div class="content-tabs content-tabs--separator"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <span class="admin-edit"
                          @click="editTranslate(['plugins_allModules'])"></span>
        <div class="content-tabs__item" v-bind:class="{active: filter === 0}"
          @click="changeFilter(0)"
        >
          {{ $t('plugins_allModules.localization_value.value') }}
        </div>
        <template v-for="(item, index) in headerDefaultDownloadPlugins">
          <div class="content-tabs__item "
               v-bind:class="{active: filter === index}"
               @click="changeFilter(index)"
               :key="index">
              <div class="content-tabs__ico">
                <img :src="item.ico" alt="ico">
              </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: "DownloadPluginsHead",
    components: {

    },

    props: [
      'filter',
    ],

    watch: {
      typeShops(newVal) {
        this.dataTypeShops = newVal
      },
    },

    data() {
      return {
        isModalChooseExpressOrderPopup: false,
        isModalDownloadOldOrdersPopup: false,
        dataTypeShops: this.typeShops,
        headerDefaultDownloadPlugins: this.$store.getters.getHeaderDefaultDownloadPlugins,
      }
    },

    methods: {
      changeFilter(val){
        this.$emit('changeFilter', val)
      }

    }
  }
</script>

<style scoped>

  .content-top-line--with-bottom-line .admin-edit{
    top: 0 !important;
  }

  .content-tabs__item{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content-tabs__ico{
    height: 40px;
  }

  .content-tabs--separator .content-tabs__item:before {
    transform: translateX(50%) translateY(-50%);
    height: 19px;
    top: calc(50% - 5px);
  }

  @media (max-width: 1600px) {
    .content-tabs__ico img {
      width: auto;
      height: 22px;
    }
  }
</style>
