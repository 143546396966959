var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"custom-popup w470",on:{"close":function($event){return _vm.$emit('close')}}},[_c('template',{slot:"header"},[_c('span',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['importO_importAOrders',])}}}),_vm._v(" "+_vm._s(_vm.$t('common_importSingleOrder.localization_value.value'))+" ")])]),_c('template',{slot:"body"},[_c('div',{staticClass:"import-transaction__content",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
        'importO_importAmazonOrders',
        'common_cancel',
        'common_send',
        ])}}}),_c('div',{staticClass:"import-transaction__row custom-row"},[_c('div',{staticClass:"import-transaction__col custom-col",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
              'importO_shopName',
            ])}}}),_c('DefaultSelect',{class:{'ui-no-valid': _vm.shopValidate},attrs:{"errorTxt":_vm.$t(`error_required.localization_value.value`),"error":_vm.shopValidate,"options":_vm.options,"label":_vm.$t('importO_shopName.localization_value.value'),"optionsLabel":'shop_name'},on:{"change":_vm.changeShop}})],1)]),_c('div',{staticClass:"import-transaction__row custom-row"},[_c('div',{staticClass:"import-transaction__col custom-col"},[_c('DefaultInput',{class:{'ui-no-valid': _vm.orderIdValidate},attrs:{"errorTxt":_vm.$t(`error_required.localization_value.value`),"error":_vm.orderIdValidate,"label":_vm.$t('importO_order.localization_value.value')},model:{value:(_vm.orderId),callback:function ($$v) {_vm.orderId=$$v},expression:"orderId"}})],1)])])]),_c('template',{slot:"footer"},[_c('div',{staticClass:"import-transaction__btn d-flex align-items-center flex-wrap"},[_c('span',{staticClass:"site-link site-link--alt import-transaction__btn-i mt-3 mr-3 mb-3",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t('common_cancel.localization_value.value'))+" ")]),_c('MainButton',{staticClass:"import-transaction__btn-i",class:{'disabled-btn' : _vm.$store.getters.getImportOrdersBtn},attrs:{"value":_vm.$t('common_send.localization_value.value')},nativeOn:{"click":function($event){return _vm.importAmazonOrders.apply(null, arguments)}}})],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }