<template>
  <modal
      @close="$emit('close')"
      class="custom-popup w470"
  >
    <template slot="header">
      <span
        v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['importO_importAOrders',])"></span>
        {{$t('common_importSingleOrder.localization_value.value')}}
      </span>
    </template>
    <template slot="body">
      <div class="import-transaction__content"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
        'importO_importAmazonOrders',
        'common_cancel',
        'common_send',
        ])"></span>
<!--        <div class="custom-popup__txt size-18px">-->
<!--          {{$t('importO_importAmazonOrders.localization_value.value')}}-->
<!--        </div>-->
        <div class="import-transaction__row custom-row">
          <div class="import-transaction__col custom-col"
            v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate([
              'importO_shopName',
            ])"></span>
            <DefaultSelect
                v-bind:class="{'ui-no-valid': shopValidate}"
                :errorTxt="$t(`error_required.localization_value.value`)"
                :error="shopValidate"
                :options="options"
                :label="$t('importO_shopName.localization_value.value')"
                :optionsLabel="'shop_name'"
                @change="changeShop"
            />
          </div>
        </div>
        <div class="import-transaction__row custom-row">
          <div class="import-transaction__col custom-col">
            <DefaultInput
                v-bind:class="{'ui-no-valid': orderIdValidate}"
                :errorTxt="$t(`error_required.localization_value.value`)"
                :error="orderIdValidate"
                :label="$t('importO_order.localization_value.value')"
                v-model="orderId"
            />
<!--            <DropZone class="drop-zone-bg"-->
<!--                      v-bind:class="{'ui-no-valid': filesValidate}"-->
<!--                      :error="filesValidate"-->
<!--                      :errorTxt="$t(`${filesValidateTxt}.localization_value.value`)"-->
<!--                      :parentFiles="files"-->
<!--                      @changeImg="changeImg"-->
<!--                      :accept="'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"-->
<!--                      :maxSize="'3'"-->
<!--            />-->
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="import-transaction__btn d-flex align-items-center flex-wrap">
        <span
            class="site-link site-link--alt import-transaction__btn-i mt-3 mr-3 mb-3"
            @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>

        <MainButton
            v-bind:class="{'disabled-btn' : $store.getters.getImportOrdersBtn}"
            :value="$t('common_send.localization_value.value')"
            class="import-transaction__btn-i"
            @click.native="importAmazonOrders"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
  // import DropZone from "../../../../coreComponents/DropZone/DropZone";
  import DefaultSelect from "../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import {
    DEFAULT_SHOP_TYPES,
    SHOPS_ADMIN_TYPES
  } from "../../../../../staticData/staticVariables";
  import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";

  export default {
    name: "ImportSingleOrderPopup",
    components: {
      DefaultInput,
      DefaultSelect,
      Modal,
      MainButton,
      // DropZone,
    },

    data(){
      return {
        files: [],
        filesValidate: false,
        filesValidateTxt: '',
        options: [],
        shop: null,
        orderId: '',
        shopValidate: false,
        orderIdValidate: false,
      }
    },

    mounted() {
      const query = new this.Query();
      let url = '?';
      let myQuery = query
          .for('posts')
      myQuery.where('shopStatus', SHOPS_ADMIN_TYPES.valid)
      myQuery.where('type', DEFAULT_SHOP_TYPES.etsy.id)
      // myQuery.where('byOauthKeysExist', '1')

      url = url + myQuery.limit(100000).page(1).url().split('?')[1]

      this.$store.dispatch('fetchShops', url).then(response => {
        this.options = this.getRespPaginateData(response)
      })
    },

    methods: {

      changeImg(files) {
        this.files = files
      },

      changeShop(val) {
        this.shop = val
      },

      importAmazonOrders() {

        this.shopValidate = !this.shop

        this.orderIdValidate = this.orderId.length === 0

        // if (this.files.length === 0) {
        //   this.filesValidate = true
        //   this.filesValidateTxt = 'error_required'
        // }
        // else if(this.files.length > 0 && (this.files[0].size / 1000000 > 3)) {
        //   this.filesValidate = true
        //   this.filesValidateTxt = 'common_sizeError'
        // }

        if (!this.shopValidate && !this.orderIdValidate) {
          // let reader = new FormData();
          // reader.append("import-file", this._.first(this.files).file)

          let data = {
            "shop_id": this.shop?.id,
            "order_id": this.orderId
          }

          this.$store.dispatch('getImportSingleOrder', data).then((response) => {

            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

                console.log('success');

                this.openNotify('success', 'common_notificationRecordChanged')
                this.$emit('reloadTable')
                this.$emit('close')

                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })
        }

      },
    }
  }

</script>

<style scoped>
  .size-18px{
    font-size: 18px;
  }

  @media(max-width: 1150px){
    .size-18px{
      font-size: 16px;
    }
  }
</style>
