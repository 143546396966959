<template>
	<div class="fragment">

		<ImportOrdersTableAllUser
						:filterGetParams="filterGetParams"
						:typeShops="navTabs"
						:countFilterParams="countFilterParams"
						@filter="filter"
						@changeShopType="changeTabAccess"
						@changeFilter="changeFilter"
						@showMore="showMore"
						@resetFilter="resetFilter"
						@downloadFiles="downloadFiles"
						@reloadTable="reload"
            @changePerPage="changePerPage"
		/>

<!--		<ImportOrdersTableAdmin-->
<!--						:filterGetParams="filterGetParams"-->
<!--						:typeShops="navTabs"-->
<!--						:countFilterParams="countFilterParams"-->
<!--						@filter="filter"-->
<!--						@changeShopType="changeTabAccess"-->
<!--						@changeFilter="changeFilter"-->
<!--						@showMore="showMore"-->
<!--						@resetFilter="resetFilter"-->
<!--						@downloadFiles="downloadFiles"-->
<!--						@reloadTable="reload"-->
<!--            @changePerPage="changePerPage"-->
<!--						v-if="$store.getters.getCurrentUserRights.additionalPermissions &&-->
<!--                $store.getters.getCurrentUserRights.additionalPermissions[PERMISSIONS.VIEW_ADMIN_ROUTE_PREFIX]"-->
<!--		/>-->

<!--		<ImportOrdersTableUser-->
<!--						:filterGetParams="filterGetParams"-->
<!--						:typeShops="navTabs"-->
<!--						:countFilterParams="countFilterParams"-->
<!--						@filter="filter"-->
<!--						@changeShopType="changeTabAccess"-->
<!--						@changeFilter="changeFilter"-->
<!--						@showMore="showMore"-->
<!--						@resetFilter="resetFilter"-->
<!--						@downloadFiles="downloadFiles"-->
<!--						@reloadTable="reload"-->
<!--            @changePerPage="changePerPage"-->
<!--						v-if="$store.getters.getCurrentUserRights.additionalPermissions &&-->
<!--                !$store.getters.getCurrentUserRights.additionalPermissions[PERMISSIONS.VIEW_ADMIN_ROUTE_PREFIX]"-->
<!--		/>-->
	</div>
</template>

<script>
	// import ImportOrdersTableAdmin from "./ImportOrdersTableAdmin/ImportOrdersTableAdmin";
	// import ImportOrdersTableUser from "./ImportOrdersTableUser/ImportOrdersTableUser.vue";
	import {shopDetails} from "../../../../../mixins/shopMixins/shopTypeFunc";
	import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {checkAccess} from "@/mixins/userCredentionalMixins/userCredentional";
  import ImportOrdersTableAllUser
    from "@/components/modules/ImportOrdersModule/components/ImportOrdersTable/ImportOrdersTableAllUser/ImportOrdersTableAllUser";

	export default {
		name: "ImportOrdersTable",
		components: {
      ImportOrdersTableAllUser,
			// ImportOrdersTableAdmin,
			// ImportOrdersTableUser,
		},

		mixins: [shopDetails, routeFilter, checkAccess],

		watch: {
			downloadPermissions() {
				if(this.navTabs.length > 0){
					this.checkTabPermission()
				}
			},
		},

		data() {
			return {
				forPage: this.$store.getters.getImportOrdersForPage,
				page: 1,
				onePage: 1,
				filterDate: [],
				filterShippedDate: [],
				filterTrackingNumber: '',
				filterTransactionNumber: '',
				filterOrder: '',
				filterCustomer: '',
				filterEmail: '',
				filterItemNumber: '',
				filterTypeName: '',
				filterUserName: '',
				filterUserId: '',
				navTabs: [],
				filterGetParams: {},
				staticParentShopTypeName: 'other',
				staticExpressPoshtaShopTypeName: 'expressPoshtaShop',
				countFilterParams: 0,
				exportIds: [],
			}
		},

		created() {
			this.$store.dispatch('fetchShopsTypes').then(() => {
				this.shopTypeFunc()
				this.navTabs = this.$store.getters.getShopTypesParent
        this.navTabs.pop()
				this.navTabs = this.navTabs.concat(this.$store.getters.getShopTypesChildWithActive)
				this.navTabs.unshift(
					{
						"active": false,
						"shop": { "id": 0, "name": "allShop",
							"parent_type_id": null,
							"translationStorage": {
								"en": { "label": "All Shop" },
								"ua": { "label": "All Shop" },
								"ru": { "label": "All Shop" }
							},
							"currentTranslate":
											{ "label": this.$t('importO_allOrders.localization_value.value') }
						}
					}
				)

        if (this._.has(this.currentPermissions, this.PERMISSIONS.ORDER_EXTERNAL_EXPRESS_POSHTA)) {
          this.navTabs.push(
              {
                "active": false,
                "shop": { "id": this.staticExpressPoshtaShopTypeName, "name": this.staticExpressPoshtaShopTypeName,
                  "parent_type_id": null,
                  "translationStorage": {
                    "en": { "label": "Express Poshta" },
                    "ua": { "label": "Express Poshta" },
                    "ru": { "label": "Express Poshta" }
                  },
                  "currentTranslate":
                      { "label": this.$t('importO_expressPoshtaShopOrders.localization_value.value') }
                }
              }
          )
        }


				this.skipRadioChecked(this.navTabs, 'active')



				if(this.downloadPermissions){
					this.checkTabPermission()
				}

			}).catch(error => this.createErrorLog(error))
		},

		methods: {

			async checkTabPermission() {
        this.activeTabByPermissions('navTabs', {
					[1]: this.PERMISSIONS.ORDER_EXTERNAL_ETSY,
					[2]: this.PERMISSIONS.ORDER_EXTERNAL_AMAZON,
					[3]: this.PERMISSIONS.ORDER_EXTERNAL_EBAY,
					[5]: this.PERMISSIONS.ORDER_EXTERNAL_OPENCART,
					[6]: this.PERMISSIONS.ORDER_EXTERNAL_WOOCOMMERCE,
					[9]: this.PERMISSIONS.ORDER_EXTERNAL_SHOPIFY,
				})
				// console.log(this.navTabs);
				// if(!this._.find(this.navTabs, {active: true}).permissionVisible){
				// 	this.setActivePermissionVisibleTab('navTabs')
        //   return
				// }

				let tabIndex = this.$route.query.type
        if(tabIndex !== undefined && tabIndex !== this.staticExpressPoshtaShopTypeName && this.navTabs[tabIndex].permissionVisible) {
				  if(parseInt(tabIndex) === 2) {
				    let access = true
				    await this.accessToImportOrdersAmazon().then(response => {
              access = response
            })
            if(!access) return
          }
					this.changeTab(parseInt(tabIndex))
				}
        else if (tabIndex === this.staticExpressPoshtaShopTypeName) {
          let findTabIndex = this.navTabs.findIndex(item => {
            return item?.shop?.name === this.staticExpressPoshtaShopTypeName
          })

          this.changeTabExpressPoshta((findTabIndex !== -1 ? findTabIndex : 0), false, tabIndex)
        }
        else {
					this.changeTab(0)
					// this.setActivePermissionVisibleTab('navTabs')
					// this.filter()
				}

			},

      async changeTabAccess(tab, tabs = false) {
        if(parseInt(tab) === 2) {
          let access = true
          await this.accessToImportOrdersAmazon().then(response => {
            access = response
          })
          if(!access) return
        }
        if (tab === this.staticExpressPoshtaShopTypeName) {
          let findTabIndex = this.navTabs.findIndex(item => {
            return item?.shop?.name === this.staticExpressPoshtaShopTypeName
          })

          this.changeTabExpressPoshta((findTabIndex !== -1 ? findTabIndex : 0), tabs, tab)
          return
        }
        this.changeTab(tab, tabs)
      },

      changeTabExpressPoshta(tab, tabs = false, tabValue = false) {
        let currentTabs = this.navTabs
        if(tabs){
          currentTabs = this[tabs]
        }
        this.skipRadioChecked(currentTabs, 'active')
        currentTabs[tab].active = true
        this.changeFilter({type: tabValue})
      },

			getFilterParam() {
				this.filterGetParams = {
					page: this.$route.query.page,
					count: this.$route.query.count,
					order: this.$route.query.order,
					customer: this.$route.query.customer,
					email: this.$route.query.email,
					itemNumber: this.$route.query.itemNumber,
					shopName: this.$route.query.shopName,
					date: this.$route.query.date,
					shippedDate: this.$route.query.shippedDate,
					trackingNumber: this.$route.query.trackingNumber,
					transactionNumber: this.$route.query.transactionNumber,
					type: this.$route.query.type,
					userName: this.$route.query.userName,
					userId: this.$route.query.userId,
				}
			},

			// changeShopType(newIndex) {
			// 	this.navTabs.map((item, index) => {
			// 		this.navTabs[index].active = false
			//
			// 		if(index === newIndex)
			// 			this.navTabs[index].active = true
			// 	})
			//
			// 	if(newIndex === 0){
			// 		this.changeFilter({shop: ''})
			// 	} else {
			// 		this.changeFilter({shop: newIndex})
			// 	}
			// },

      changePerPage(val) {
        this.$store.commit('setImportOrdersForPage', val)
        this.forPage = this.$store.getters.getImportOrdersForPage

        let query = Object.assign({}, this.$route.query);
        query.count = this.$store.getters.getImportOrdersForPage
        this.$router.replace({query})

        if (this.$route.query.page > Math.ceil(this.$store.getters.getImportOrdersCommonList.total / this.$store.getters.getImportOrdersForPage)) {
          this.filterGetParams.page = Math.ceil(this.$store.getters.getImportOrdersCommonList.total / this.$store.getters.getImportOrdersForPage)
          query.page = this.filterGetParams.page
          this.$router.replace({query})
        }

        this.filter(false)
      },

			filter(next = false) {
				this.getFilterParam()
				this.checkCountFilter(['type', 'userName'])
				let forPage, page, url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

				// Generate filter URL
				url = this.generateFilterUrl(page, forPage)

				// True if get next page
				if(next) this.$store.commit('setNextImportOrdersPage', true)

				// save filter params in store
				this.$store.commit('setImportOrdersFilter', window.location.search)

				this.$store.dispatch('fetchImportOrders', url).then(() => {
					// skip get next page
					this.$store.commit('setNextImportOrdersPage', false)
				})
			},

			generateFilterUrl(page, forPage, exportIds = false) {
				let myQuery = this.createQueryBuilderObject({withUserNewMark: true})


        /**
         * Enable related order instances
         */
        myQuery.appends('orderInstance')

				if(this.filterTrackingNumber !== '') myQuery.where('oexTrackingNumber', this.filterTrackingNumber)
				if(this.filterTransactionNumber !== '') myQuery.where('oexTransactionNumber', this.filterTransactionNumber)
				if(this.filterOrder !== '') myQuery.where('oexId', this.filterOrder)
				if(this.filterCustomer !== '') myQuery.where('oexCustomer', this.filterCustomer)
				if(this.filterEmail !== '') myQuery.where('oexEmail', this.filterEmail)
				if(this.filterItemNumber !== '') myQuery.where('oexItemNumber', this.filterItemNumber)
				if(this.filterShopName !== '') myQuery.where('oexShopName', this.filterShopName)
				if(this.filterUserId !== '') myQuery.where('oexUserId', this.filterUserId)

				if(this.navTabs[0].active)
					myQuery.sort('-oexId')
				else myQuery.sort('-oexOriginalCreatedAt')

				this.navTabs.map((item) => {
					if(item.active){
						if(item.shop.id !== 0 && item.shop.name !== this.staticParentShopTypeName && item.shop.name !== this.staticParentShopTypeName &&
                item.shop.name !== this.staticExpressPoshtaShopTypeName){
							myQuery.where('oexShopTypeId', item.shop.id)
						} else if (item.shop.name === this.staticParentShopTypeName) {
							myQuery.where('parent_type_id', item.shop.id)
						}
            else if (item.shop.name === this.staticExpressPoshtaShopTypeName) {
              myQuery.where('expressPoshta', '1')
            }
					}
				})

				// If generate on export file
				if(exportIds) {
					myQuery.whereIn('oexId', exportIds)
				}

				this.filterDate = this.$route.query.date !== undefined ? this.$route.query.date : ''
				if(this.filterDate.length > 0){
					let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')
					myQuery.whereIn('oexOriginalCreatedAt', date)
				}

				this.filterShippedDate = this.$route.query.shippedDate !== undefined ? this.$route.query.shippedDate : ''
				if(this.filterShippedDate.length > 0){
					let date = this.generateDateForFilter(this.filterShippedDate, 'YYYY-MM-DD')
					myQuery.whereIn('oexShippedAt', date)
				}


				return this.generateGetParamsFromQuery(myQuery, forPage, page)
			},

			downloadFiles(type = 'xls') {
				let forPage, page, url, exportIds

				exportIds = this.getCheckedRows('row-name')

				// generate main filter if has get params in URL
				let generateMainFilter = this.generateMainFilterFromUrl(false, this)

				page = generateMainFilter.page
				forPage = generateMainFilter.forPage

				url = this.generateFilterUrl(page, forPage, exportIds)

        if (type === 'xls') {
          type = 'xlsx'
        }

				this.$store.dispatch('getExportImportOrders', {filter: url , type: type}).then((response) => {
					const blob = new Blob([response.data], { type: `application/${type}` })
					const link = document.createElement('a')
					link.href = URL.createObjectURL(blob)
					link.download = `Import Orders.${type}`
					link.click()
					URL.revokeObjectURL(link.href)
				}).catch(error => this.createErrorLog(error))

			},

		},

	}
</script>

<style scoped>

</style>
