<template>
  <modal
      @close="$emit('close')"
      class="what-order-create-modal what-order-create"
  >
    <template slot="header">
      <span
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['importO_whatOrderCreate'])"></span>
      {{$t('importO_whatOrderCreate.localization_value.value')}}
      </span>
    </template>
    <template slot="body">
      <div class="what-order-create__list custom-row"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['importO_orderForConsolidation', 'importO_orderFromWarehouse', 'importO_createLabel'])"></span>

        <div class="what-order-create__item custom-col custom-col--33 custom-col--xs-100"
             v-if="consolidation">
          <router-link
              :to="$store.getters.GET_PATHS.ordersFBM + consolidation"
              class="what-order-create__link"
          >
            <div class="what-order-create__ico">
              <OrderForConsolidationIco/>
            </div>
            {{$t('importO_orderForConsolidation.localization_value.value')}}
          </router-link>
        </div>
        <div class="what-order-create__item custom-col custom-col--33 custom-col--xs-100"
             v-if="warehouse">
          <router-link
              :to="$store.getters.GET_PATHS.ordersFBM + warehouse"
              class="what-order-create__link"
          >
            <div class="what-order-create__ico">
              <OrderFromWarehouseIco/>
            </div>
            {{$t('importO_orderFromWarehouse.localization_value.value')}}
          </router-link>
        </div>
        <div class="what-order-create__item custom-col custom-col--33 custom-col--xs-100"
             v-if="label">
          <router-link
              :to="$store.getters.GET_PATHS.ordersFBM + label"
              class="what-order-create__link"
          >
            <div class="what-order-create__ico">
              <CreateLabelIco/>
            </div>
            {{$t('importO_createLabel.localization_value.value')}}
          </router-link>
        </div>
      </div>

      <div class="choose-express-order__list custom-row mt-4">
<!--        <div class="choose-express-order__item custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-100"-->
<!--             v-if="tnt">-->
<!--        <router-link-->
<!--                :to="$store.getters.GET_PATHS.expressOrders + tnt"-->
<!--          >-->
<!--            <OrderTypeCardBig-->
<!--                    :srcImg="'/img/company-icons-group/popup-tnt.png'">-->

<!--            </OrderTypeCardBig>-->
<!--          </router-link>-->
<!--        </div>-->
        <div class="choose-express-order__item custom-col custom-col--33 custom-col--md-100"
             v-if="dhl">
          <router-link
                  :to="$store.getters.GET_PATHS.expressOrders + dhl"
          >
            <OrderTypeCardBig
                    :srcImg="'/img/company-icons-group/popup-dhl.png'">

            </OrderTypeCardBig>
          </router-link>
        </div>
        <div class="choose-express-order__item custom-col custom-col--33 custom-col--md-100"
             v-if="fedex">
          <router-link
                  :to="$store.getters.GET_PATHS.expressOrders + fedex"
          >
            <OrderTypeCardBig
                    :srcImg="'/img/company-icons-group/popup-fedex.png'">

            </OrderTypeCardBig>
          </router-link>
        </div>

      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'

  import CreateLabelIco from '../../../../../../public/img/modal-group/create-label.svg?inline'
  import OrderFromWarehouseIco from '../../../../../../public/img/modal-group/Order-from-Warehouse.svg?inline'
  import OrderForConsolidationIco from '../../../../../../public/img/modal-group/Order-for-Consolidation.svg?inline'
  import OrderTypeCardBig from "../../../../coreComponents/OrderTypeCardBig/OrderTypeCardBig";


  export default {
    name: "AdminOrderCreatePopup",
    components: {
      Modal,
      CreateLabelIco,
      OrderFromWarehouseIco,
      OrderForConsolidationIco,
      OrderTypeCardBig,
    },

    props: {
      dhl: {
        type: String,
        default: '',
      },
      fedex: {
        type: String,
        default: '',
      },
      tnt: {
        type: String,
        default: '',
      },

      consolidation: {
        type: String,
        default: '',
      },
      warehouse: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
    },
  }

</script>

<style lang="scss">
  @import "../../../../../scss/mixins/mixins";
  @import "../../../../../scss/colors";

</style>
