<template>
	<div class="page-content">
		<Breadcrumb/>
    <SearchEngine
        v-if="isAdmin"
    />


		<router-view></router-view>

	</div>
</template>

<script>
	import Breadcrumb from "../../templates/Breadcrumb/Breadcrumb.vue";
  import SearchEngine from "../../coreComponents/SearchEngine/SearchEngine";

	export default {
		name: "DownloadPluginsModule",

		components:{
      SearchEngine,
			Breadcrumb,
		},

		data(){
			return {
			}
		},

		methods: {

		}
	}
</script>

<style scoped>

</style>
