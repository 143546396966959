var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"custom-popup medium-size",on:{"close":function($event){return _vm.$emit('close')}}},[_c('template',{slot:"header"},[_c('span',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['importO_importAOrders',])}}}),_vm._v(" "+_vm._s(_vm.$t('importO_importAOrders.localization_value.value'))+" ")])]),_c('template',{slot:"body"},[_c('div',{staticClass:"import-transaction__content",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
      'importO_importAmazonOrders',
      'common_cancel',
      'common_send',
      ])}}}),_c('div',{staticClass:"custom-popup__txt size-18px"},[_vm._v(" "+_vm._s(_vm.$t('importO_importAmazonOrders.localization_value.value'))+" ")]),_c('div',{staticClass:"import-transaction__row custom-row"},[_c('div',{staticClass:"import-transaction__col custom-col",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
            'importO_shopName',
          ])}}}),_c('DefaultSelect',{class:{'ui-no-valid': _vm.shopValidate},attrs:{"errorTxt":_vm.$t(`error_required.localization_value.value`),"error":_vm.shopValidate,"options":_vm.options,"label":_vm.$t('importO_shopName.localization_value.value'),"optionsLabel":'shop_name'},on:{"change":_vm.changeShop}})],1)]),_c('div',{staticClass:"import-transaction__row custom-row"},[_c('div',{staticClass:"import-transaction__col custom-col"},[_c('DropZone',{staticClass:"drop-zone-bg",class:{'ui-no-valid': _vm.filesValidate},attrs:{"error":_vm.filesValidate,"errorTxt":_vm.$t(`${_vm.filesValidateTxt}.localization_value.value`),"parentFiles":_vm.files,"accept":'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',"maxSize":'3'},on:{"changeImg":_vm.changeImg}})],1)])])]),_c('template',{slot:"footer"},[_c('div',{staticClass:"import-transaction__btn d-flex align-items-center flex-wrap"},[_c('span',{staticClass:"site-link site-link--alt import-transaction__btn-i mt-3 mr-3 mb-3",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t('common_cancel.localization_value.value'))+" ")]),_c('MainButton',{staticClass:"import-transaction__btn-i",class:{'disabled-btn' : _vm.$store.getters.getImportOrdersBtn},attrs:{"value":_vm.$t('common_send.localization_value.value')},nativeOn:{"click":function($event){return _vm.importAmazonOrders.apply(null, arguments)}}})],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }