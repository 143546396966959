<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row">
        <DefaultCheckbox class="mt-1"
           :selectedNow="selectedNow"
           :dataValue="item.id"
        />
        <div class="table-card__item-number cursor-pointer" @click="$emit('showOrderInfo')">
          #{{item.id}}
        </div>
      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          {{ item.original_created_at | moment("DD MMM, YYYY") }}
        </div>
      </div>
    </div>

    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('importO_order.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              #{{item.id}}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('importO_created.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{ item.original_created_at | moment("DD MMM, YYYY") }}
            </div>
          </div>

          <div class="table-card__item-col custom-col" v-if="isAdmin">
            <div class="table-card__item-label">
              {{$t('common_user.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <TableUserColumn
                      :item="item"
                      :mobile="true"
              />
            </div>
          </div>

          <div class="table-card__item-col custom-col" v-if="!checkOpenCart && !checkWooCommerce && !checkExpressPoshta">
            <div class="table-card__item-label">
              {{ $t('importO_payPalTransaction.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <!--              {{item.transaction_number !== null ? item.transaction_number : '-'}}-->
              {{item.payment_transaction !== null ? item.payment_transaction.transaction_id : '-'}}
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('importO_customer.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{item.original_first_name}} {{item.original_last_name}}
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('importO_eMail.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{item.original_email}}
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('importO_trackingNumber.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <template v-if="item.tracking_number !== null">
                <!--<span>{{item.tracking_number}}</span>-->
                <span v-bind:class="{'table-link btn-style': item.tracking_sent === 0}">{{item.tracking_number}}</span>
                <div class="table-ico">
                  <v-popover
                          v-if="item.tracking_sent === 0"
                          class="site-tooltip"
                          offset="5"
                          placement="top-left"
                          @show="hideEditField = true"
                          @hide="hideEditField = false"
                  >
                    <span class="site-link">
                        <LinkButton
                                :type="'edit'"
                        />
                    </span>
                    <template slot="popover">
                      <div class="section-label mb-2">{{ $t('importO_trackingNumber.localization_value.value') }}</div>
                      <InputEditField
                              :value="item.tracking_number"
                              :propId="item.id"
                              :hideEditField="hideEditField"
                              @onChange="onChange"
                      />
                      <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                    </template>
                  </v-popover>
                </div>
              </template>
              <template v-else>
                <div class="">
                  <v-popover
                          class="site-tooltip"
                          offset="5"
                          placement="top-left"
                          @show="hideEditField = true"
                          @hide="hideEditField = false"
                  >
                    <span class="table-link btn-style">Add Number </span>

                    <template slot="popover">
                      <div class="section-label mb-2">Tracking Number</div>
                      <InputEditField
                              :value="''"
                              :propId="item.id"
                              :hideEditField="hideEditField"
                              @onChange="onChange"
                      />

                      <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                    </template>
                  </v-popover>
                </div>
              </template>
            </div>
          </div>
          <div class="table-card__item-col custom-col" v-if="checkExpressPoshta">
            <div class="table-card__item-label">
              {{ $t('importO_orderType.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <!--              {{item.transaction_number !== null ? item.transaction_number : ''}}-->
              {{item.order_type}}
            </div>
          </div>
          <div class="table-card__item-col custom-col" v-if="!isAdmin">
            <div class="table-card__item-label">
              {{ $t('importO_shopName.localization_value.value') }}
              <!--{{item.item_number}}-->
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item"
                  :deep="'shop.shop_name'"
              />
              <!--Treasures-->
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('importO_itemNumber.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{item.item_number}}
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('importO_shipped.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <template v-if="item.shipped_at !== null">
                {{item.shipped_at | moment("DD MMM, YYYY")}}. at {{item.shipped_at | moment("HH:MM")}}
              </template>
              <template v-else>
                -
              </template>
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('importO_total.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{item.original_total !== null ? Number(item.original_total) : '-'}}
            </div>
          </div>
        </div>

        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i" v-if="isAdmin">
            <LinkButton
                    :type="'delete'"
                    @click.native="$emit('removeOrder')"
            />
          </div>

          <ManageOrdersInTable
                  :item="item"
                  :typeTransaction="'order'"
                  :newOrderLink="true"
                  @showWhatOrderCreatePopup="(itemS, getExpressLinks, getFBMLinks) => {$emit('showWhatOrderCreatePopup', itemS, getExpressLinks, getFBMLinks)}"
                  @showChooseExpressOrder="(itemS, links) => {$emit('showChooseExpressOrder', itemS, links)}"
                  @showChooseOrderFBMCreatePopup="(itemS, getFBMLinks) => {$emit('showChooseOrderFBMCreatePopup', itemS, getFBMLinks)}"
          />

        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import InputEditField from "../../../../../../../UI/inputs/InputEditField/InputEditField";
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import ManageOrdersInTable
    from "../../../../../../../coreComponents/TableComponents/ManageOrdersInTable/ManageOrdersInTable";
  import TableUserColumn from "../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";

  export default {
    name: "ImportOrdersMobileTable",
    components: {
      TableUserColumn,
      ValueHelper,
      ManageOrdersInTable,
      InputEditField,
      LinkButton,
      DefaultCheckbox,
    },

    computed: {
      checkOpenCart() {
        return this._.find(this.typeShops, {active: true}).shop.name === 'opencart'
      },
      checkWooCommerce() {
        return this._.find(this.typeShops, {active: true}).shop.name === 'woocommerce'
      },
      checkExpressPoshta() {
        return this._.find(this.typeShops, {active: true}).shop.name === 'expressPoshtaShop'
      },
    },

    data() {
      return {
        show: false,
        hideEditField: false,
      }
    },

    props: ['item', 'selectedNow', 'typeShops'],

    watch: {
      item(newVal) {
        this.item = newVal
      },
    },

    methods: {
      onChange({value, id}) {
        this.$emit('changeTrackingNumber', {value: value, id: id, ref: this.$refs.closePopover})
      },
    }
  }
</script>

<style scoped lang="scss">

  /deep/ .table-card__item-btn .table-right__ico{
    margin-left: 15px;
    margin-top: -1px;
  }

</style>
