<template>
	<div class="fragment">

		<ImportOrdersTableUser
						@changeFilter="changeFilter"
						:filter="filter"
		/>
	</div>
</template>

<script>
	import ImportOrdersTableUser from "./DownloadPluginsUser/DownloadPluginsUser.vue";
	import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";

	export default {
		name: "DownloadPluginsTablePage",
		components: {
			ImportOrdersTableUser,
		},

		mixins: [routeFilter],

		data() {
			return {
				filter: 0,
			}
		},

		created() {
			this.$store.dispatch('fetchDownloadPlugins')
		},

		methods: {

			changeFilter(data) {
				this.filter = data
			},

		},

	}
</script>

<style scoped>

</style>
